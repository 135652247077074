<template>
  <LayoutViewWrapper>
    <ProgressBar :progress="progress" class="progress-bar" />
    <router-view />
    <NavMobileBack v-if="isMobile" />
  </LayoutViewWrapper>
</template>

<script>
import { useApplicationNannyStore } from "@/stores/applicationNanny";
import { onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { ROUTE_APPLICATION_NANNY_CREATE_NAME } from "@/router/constants";
import useProgressBar from "@/composables/useProgressBar.js";

import NavMobileBack from "@/components/NavMobileBack.vue";
import useIsMobile from "@/composables/useIsMobile";
import LayoutViewWrapper from "@/layouts/LayoutViewWrapper.vue";
import ProgressBar from "@/components/UI/ProgressBar.vue";

export default {
  components: { NavMobileBack, LayoutViewWrapper, ProgressBar },

  setup() {
    const applicationNanny = useApplicationNannyStore();
    const router = useRouter();
    const { isMobile } = useIsMobile();
    const { progress } = useProgressBar();

    const initApplicationDraft = async () => {
      try {
        await applicationNanny.initApplicationDraft();
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    if (applicationNanny.storeMeta.isInitialized === false) {
      initApplicationDraft();
      router.push({ name: ROUTE_APPLICATION_NANNY_CREATE_NAME });
    }

    onUnmounted(() => {
      // Reset application if it was published
      if (applicationNanny.meta.status === "published") {
        applicationNanny.reset();
      }
    });

    return {
      isMobile,
      progress,
    };
  },
};
</script>

<style scoped>
.progress-bar {
  margin: -20px 0 30px;
}

@media (max-width: 1200px) {
  .progress-bar {
    margin: 10px 0 30px;
  }
}
</style>
